'use client'
import React from 'react'
import { Button } from "antd";
import Link from 'next/link'
import Logo from '@/components/Logo'; 

const page = () => {
    return (
        <div className="bg-white h-screen flex flex-col justify-center items-center relative">
          <header className="absolute top-5 w-[90%] flex justify-between items-center">
            <Logo /> {/* Renderiza tu logo */}
          </header>
    
          <main className="text-center px-12">
            <h1 className="text-6xl mb-4 text-[#106239] font-bold">SCALA se despide</h1>
            <p className="text-lg mb-8 text-gray-700">
              Lo sentimos, pero por diversos motivos SCALA se despide de sus clientes. Para cualquier duda o aclaración, puedes contactarnos a través de nuestro correo <a href="mailto:hola@antilavado.mx">ayuda@antilavado.mx</a>
            </p>
    
            {/* <Form layout="vertical" className="max-w-sm mx-auto">
              <Form.Item label="Correo Electrónico">
                <Input placeholder="Ingresa tu correo electrónico" />
              </Form.Item>
              <Form.Item label="Código de Invitación">
                <Input placeholder="Ingresa tu código de invitación" />
              </Form.Item>
              <Button type="primary" block>SOLICITAR ACCESO</Button>
            </Form> */}
          </main>
        </div>
      );
}

export default page